import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const MitarbeiterListPage = () =>
  import('@/modules/mitarbeiter/components/mitarbeiter-list-page.vue');
const MitarbeiterFormPage = () =>
  import('@/modules/mitarbeiter/components/mitarbeiter-form-page.vue');
const MitarbeiterViewPage = () =>
  import('@/modules/mitarbeiter/components/mitarbeiter-view-page.vue');
const MitarbeiterImporterPage = () =>
  import('@/modules/mitarbeiter/components/mitarbeiter-importer-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'mitarbeiter',
        path: '/mitarbeiter',
        component: MitarbeiterListPage,
        meta: {
          auth: true,
          permission: Permissions.values.mitarbeiterNavigate,
        },
      },
      {
        name: 'mitarbeiterImporter',
        path: '/mitarbeiter/import',
        component: MitarbeiterImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.mitarbeiterImport,
        },
      },
      {
        name: 'mitarbeiterNew',
        path: '/mitarbeiter/new',
        component: MitarbeiterFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.mitarbeiterNavigate,
        },
      },
      {
        name: 'mitarbeiterEdit',
        path: '/mitarbeiter/:id/edit',
        component: MitarbeiterFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.mitarbeiterNavigate,
        },
        props: true,
      },
      {
        name: 'mitarbeiterView',
        path: '/mitarbeiter/:id',
        component: MitarbeiterViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.mitarbeiterNavigate,
        },
        props: true,
      },
    ],
  },
];
