/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
module.exports = class Storage {
  static get values() {
    return {
      userProfileAvatar: {
        id: 'userProfileAvatar',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 1 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      vorlageDienstgeber: {
        id: 'vorlageDienstgeber',
        folder: 'vorlageDienstgeber',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      vorlageDienstnehmer: {
        id: 'vorlageDienstnehmer',
        folder: 'vorlageDienstnehmer',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      vertragVertragsentwurf: {
        id: 'vertragVertragsentwurf',
        folder: 'mitarbeiter/vertragsentwurf',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      vertragUnterschriebenerVertrag: {
        id: 'vertragUnterschriebenerVertrag',
        folder: 'mitarbeiter/unterschriebenerVertrag',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      vertragsergaenzungEntwurf: {
        id: 'vertragsergaenzungEntwurf',
        folder: 'mitarbeiter/vertragsergaenzungEntwurf',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      vertragsergaenzungUnterschriebenerVertrag: {
        id: 'vertragsergaenzungUnterschriebenerVertrag',
        folder: 'mitarbeiter/unterschriebenerVertragsergaenzung',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      vertragsaenderungEntwurf: {
        id: 'vertragsaenderungEntwurf',
        folder: 'mitarbeiter/vertragsaenderungEntwurf',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      vertragsaenderungUnterschriebenerVertrag: {
        id: 'vertragsaenderungUnterschriebenerVertrag',
        folder: 'mitarbeiter/unterschriebenerVertragsaenderung',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      beendigungKuendigungEntwurf: {
        id: 'beendigungKuendigungEntwurf',
        folder: 'mitarbeiter/kuendigungEntwurf',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      beendigungUnterschriebeneKuendigung: {
        id: 'beendigungUnterschriebeneKuendigung',
        folder: 'mitarbeiter/unterschriebeneKuendigung',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      knowledgeBaseDocument: {
        id: 'knowledgeBaseDocument',
        folder: 'knowledgeBase/document',
        maxSizeInBytes: 20 * 1024 * 1024,
      },
      mitarbeiterPersoenlicheData: {
        id: 'mitarbeiterPersoenlicheData',
        folder: 'mitarbeiter/persoenlicheData',
        maxSizeInBytes: 20 * 1024 * 1024,
      },
      mitarbeiterMitteilung: {
        id: 'mitarbeiterMitteilung',
        folder: 'mitarbeiter/mitteilung',
        maxSizeInBytes: 20 * 1024 * 1024,
      },
      mitarbeiterKarenz: {
        id: 'mitarbeiterKarenz',
        folder: 'mitarbeiter/karenz',
        maxSizeInBytes: 20 * 1024 * 1024,
      },
      mitarbeiterKrankmeldung: {
        id: 'mitarbeiterKrankmeldung',
        folder: 'mitarbeiter/krankmeldung',
        maxSizeInBytes: 20 * 1024 * 1024,
      },
    };
  }
};
