import Roles from '@/security/roles';
import Storage from '@/security/storage';

const roles = Roles.values;
const storage = Storage.values;

//TODO
//Make sure that permission on the frontend and backend are same
//Review all permissions

class Permissions {
  static get values() {
    return {
      userNavigate: {
        id: 'userNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.userProfileAvatar],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      userUserAutocomplete: {
        id: 'userUserAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
              
                funktionCreate: {
        id: 'funktionCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      funktionEdit: {
        id: 'funktionEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      funktionRead: {
        id: 'funktionRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      funktionDestroy: {
        id: 'funktionDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      funktionAutocomplete: {
        id: 'funktionAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },


      einstufungNavigate: {
        id: 'einstufungNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      einstufungCreate: {
        id: 'einstufungCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      einstufungEdit: {
        id: 'einstufungEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      einstufungDestroy: {
        id: 'einstufungDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      einstufungRead: {
        id: 'einstufungRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      einstufungAutocomplete: {
        id: 'einstufungAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      einstufungImport: {
        id: 'einstufungImport',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },

      auditLogNavigate: {
        id: 'auditLogNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },

      settingsNavigate: {
        id: 'settingsNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },

      changeOwnPassword: {
        id: 'changeOwnPassword',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      mitarbeiterNavigate: {
        id: 'mitarbeiterNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      mitarbeiterCreate: {
        id: 'mitarbeiterCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.mitarbeiterPersoenlicheData,
          storage.mitarbeiterMitteilung,
          storage.mitarbeiterKarenz,
          storage.mitarbeiterKrankmeldung,
        ],
      },
      mitarbeiterEdit: {
        id: 'mitarbeiterEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.mitarbeiterPersoenlicheData,
          storage.mitarbeiterMitteilung,
          storage.mitarbeiterKarenz,
          storage.mitarbeiterKrankmeldung,
        ],
      },
      mitarbeiterEditMitarbeiterNr: {
        id: 'mitarbeiterEditMitarbeiterNr',
        allowedRoles: [roles.personalAdmin],
      },
      mitarbeiterEditInactiveMitarbeiter: {
        id: 'mitarbeiterEditInactiveMitarbeiter',
        allowedRoles: [roles.personalAdmin],
      },
      mitarbeiterAbmelden: {
        id: 'mitarbeiterAbmelden',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      mitarbeiterSetKarenz: {
        id: 'mitarbeiterSetKarenz',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      mitarbeiterRemoveKarenz: {
        id: 'mitarbeiterRemoveKarenz',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      mitarbeiterCreateKostenstelle: {
        id: 'mitarbeiterCreateKostenstelle',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      mitarbeiterDestroyKostenstelle: {
        id: 'mitarbeiterDestroyKostenstelle',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      mitarbeiterEditKostenstelle: {
        id: 'mitarbeiterEditKostenstelle',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      mitarbeiterSetBackToActive: {
        id: 'mitarbeiterSetBackToActive',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      mitarbeiterImport: {
        id: 'mitarbeiterImport',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      mitarbeiterExport: {
        id: 'mitarbeiterExport',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      mitarbeiterDestroy: {
        id: 'mitarbeiterDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [
          storage.mitarbeiterPersoenlicheData,
          storage.mitarbeiterMitteilung,
          storage.mitarbeiterKarenz,
          storage.mitarbeiterKrankmeldung,
        ],
      },
      mitarbeiterRead: {
        id: 'mitarbeiterRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.mitarbeiterPersoenlicheData,
          storage.mitarbeiterMitteilung,
          storage.mitarbeiterKarenz,
          storage.mitarbeiterKrankmeldung,
        ],
      },
      mitarbeiterAutocomplete: {
        id: 'mitarbeiterAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      mitarbeiterFileFields: {
        id: 'mitarbeiterFileFields',
        fields: {
          persoenlicheData: {
            id: 'persoenlicheData',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin, roles.personal],
          },
          mitteilung: {
            id: 'mitteilung',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin, roles.personal],
          },
          karenz: {
            id: 'karenz',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin, roles.personal],
          },
          krankmeldung: {
            id: 'krankmeldung',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin, roles.personal],
          },
        },
      },

      vertragCreate: {
        id: 'vertragCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.vertragVertragsentwurf,
          storage.vertragUnterschriebenerVertrag,
        ],
      },
      vertragEdit: {
        id: 'vertragEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.vertragVertragsentwurf,
          storage.vertragUnterschriebenerVertrag,
        ],
      },
      vertragDestroy: {
        id: 'vertragDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [
          storage.vertragVertragsentwurf,
          storage.vertragUnterschriebenerVertrag,
        ],
      },
      vertragSetToInactive: {
        id: 'vertragSetToInactive',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      vertragSendEmailToEmployee: {
        id: 'vertragSendEmailToEmployee',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vertragSendEmailToEmployeeMultipleTimes: {
        id: 'vertragSendEmailToEmployeeMultipleTimes',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      vertragRead: {
        id: 'vertragRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.vertragVertragsentwurf,
          storage.vertragUnterschriebenerVertrag,
        ],
      },
      vertragFileFields: {
        id: 'vertragFileFields',
        type: 'files',
        fields: {
          vertragsentwurf: {
            id: 'vertragsentwurf',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
          unterschriebenerVertrag: {
            id: 'unterschriebenerVertrag',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
        },
      },

      vertragsergaenzungCreate: {
        id: 'vertragsergaenzungCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.vertragsergaenzungEntwurf,
          storage.vertragsergaenzungUnterschriebenerVertrag,
        ],
      },
      vertragsergaenzungEdit: {
        id: 'vertragsergaenzungEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.vertragsergaenzungEntwurf,
          storage.vertragsergaenzungUnterschriebenerVertrag,
        ],
      },
      vertragsergaenzungDestroy: {
        id: 'vertragsergaenzungDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [
          storage.vertragsergaenzungEntwurf,
          storage.vertragsergaenzungUnterschriebenerVertrag,
        ],
      },
      vertragsergaenzungSetToInactive: {
        id: 'vertragsergaenzungSetToInactive',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      vertragsergaenzungSendEmailToEmployee: {
        id: 'vertragsergaenzungSendEmailToEmployee',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vertragsergaenzungSendEmailToEmployeeMultipleTimes: {
        id: 'vertragsergaenzungSendEmailToEmployeeMultipleTimes',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      vertragsergaenzungRead: {
        id: 'vertragRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.vertragsergaenzungEntwurf,
          storage.vertragsergaenzungUnterschriebenerVertrag,
        ],
      },
      vertragsergaenzungFileFields: {
        id: 'vertragFileFields',
        type: 'files',
        fields: {
          entwurf: {
            id: 'entwurf',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
          unterschriebenerVertragsergaenzung: {
            id: 'unterschriebenerVertragsergaenzung',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
        },
      },

      vertragsaenderungCreate: {
        id: 'vertragsaenderungCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.vertragsaenderungEntwurf,
          storage.vertragsaenderungUnterschriebenerVertrag,
        ],
      },
      vertragsaenderungEdit: {
        id: 'vertragsaenderungEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.vertragsaenderungEntwurf,
          storage.vertragsaenderungUnterschriebenerVertrag,
        ],
      },
      vertragsaenderungDestroy: {
        id: 'vertragsaenderungDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [
          storage.vertragsaenderungEntwurf,
          storage.vertragsaenderungUnterschriebenerVertrag,
        ],
      },
      vertragsaenderungSetToInactive: {
        id: 'vertragsaenderungSetToInactive',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      vertragsaenderungSendEmailToEmployee: {
        id: 'vertragsaenderungSendEmailToEmployee',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vertragsaenderungSendEmailToEmployeeMultipleTimes: {
        id: 'vertragsaenderungSendEmailToEmployeeMultipleTimes',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      vertragsaenderungRead: {
        id: 'vertragRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.vertragsaenderungEntwurf,
          storage.vertragsaenderungUnterschriebenerVertrag,
        ],
      },
      vertragsaenderungFileFields: {
        id: 'vertragFileFields',
        type: 'files',
        fields: {
          entwurf: {
            id: 'entwurf',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
          unterschriebenerVertragsaenderung: {
            id: 'unterschriebenerVertragsaenderung',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
        },
      },
      beendigungCreate: {
        id: 'beendigungCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.beendigungKuendigungEntwurf,
          storage.beendigungUnterschriebeneKuendigung,
        ],
      },
      beendigungEdit: {
        id: 'beendigungEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
        allowedStorage: [
          storage.beendigungKuendigungEntwurf,
          storage.beendigungUnterschriebeneKuendigung,
        ],
      },
      beendigungDestroy: {
        id: 'beendigungDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [
          storage.beendigungKuendigungEntwurf,
          storage.beendigungUnterschriebeneKuendigung,
        ],
      },
      beendigungRead: {
        id: 'beendigungRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      beendigungSendEmailToEmployee: {
        id: 'beendigungSendEmailToEmployee',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      beendigungFileFields: {
        id: 'beendigungFileFields',
        type: 'files',
        fields: {
          kuendigungEntwurf: {
            id: 'kuendigungEntwurf',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
          unterschriebeneKuendigung: {
            id: 'unterschriebeneKuendigung',
            upload: [roles.itAdmin, roles.personalAdmin, roles.personal],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
        },
      },

      vorlageDienstgeberNavigate: {
        id: 'vorlageDienstgeberNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vorlageDienstgeberCreate: {
        id: 'vorlageDienstgeberCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.vorlageDienstgeber],
      },
      vorlageDienstgeberEdit: {
        id: 'vorlageDienstgeberEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.vorlageDienstgeber],
      },
      vorlageDienstgeberDestroy: {
        id: 'vorlageDienstgeberDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.vorlageDienstgeber],
      },
      vorlageDienstgeberRead: {
        id: 'vorlageDienstgeberRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vorlageDienstgeberAutocomplete: {
        id: 'vorlageDienstgeberAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vorlageDienstgeberFileFields: {
        id: 'vorlageDienstgeberFileFields',
        fields: {
          vorlage: {
            id: 'vorlage',
            upload: [roles.itAdmin, roles.personalAdmin],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
        },
      },
      vorlageDienstnehmerNavigate: {
        id: 'vorlageDienstnehmerNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vorlageDienstnehmerCreate: {
        id: 'vorlageDienstnehmerCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.vorlageDienstnehmer],
      },
      vorlageDienstnehmerEdit: {
        id: 'vorlageDienstnehmerEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.vorlageDienstnehmer],
      },
      vorlageDienstnehmerDestroy: {
        id: 'vorlageDienstnehmerDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.vorlageDienstnehmer],
      },
      vorlageDienstnehmerRead: {
        id: 'vorlageDienstnehmerRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vorlageDienstnehmerAutocomplete: {
        id: 'vorlageDienstnehmerAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vorlageDienstnehmerFileFields: {
        id: 'vorlageDienstnehmerFileFields',
        fields: {
          vorlage: {
            id: 'vorlage',
            upload: [roles.itAdmin, roles.personalAdmin],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
        },
      },

      fakultaetNavigate: {
        id: 'fakultaetNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      fakultaetCreate: {
        id: 'fakultaetCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      fakultaetEdit: {
        id: 'fakultaetEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      fakultaetDestroy: {
        id: 'fakultaetDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      fakultaetRead: {
        id: 'fakultaetRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      fakultaetAutocomplete: {
        id: 'fakultaetAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      staatsbuergerschaftNavigate: {
        id: 'staatsbuergerschaftNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      staatsbuergerschaftCreate: {
        id: 'fakultaetCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      staatsbuergerschaftEdit: {
        id: 'staatsbuergerschaftEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      staatsbuergerschaftDestroy: {
        id: 'staatsbuergerschaftDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      staatsbuergerschaftImport: {
        id: 'staatsbuergerschaftImport',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      staatsbuergerschaftRead: {
        id: 'staatsbuergerschaftRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      staatsbuergerschaftAutocomplete: {
        id: 'staatsbuergerschaftAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      vertragsartNavigate: {
        id: 'vertragsartNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vertragsartCreate: {
        id: 'vertragsartCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      vertragsartEdit: {
        id: 'vertragsartEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      vertragsartDestroy: {
        id: 'vertragsartDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      vertragsartRead: {
        id: 'vertragsartRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      vertragsartAutocomplete: {
        id: 'vertragsartAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      knowledgeBaseNavigate: {
        id: 'knowledgeBaseNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      knowledgeBaseCreate: {
        id: 'knowledgeBaseCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.knowledgeBaseDocument],
      },
      knowledgeBaseEdit: {
        id: 'knowledgeBaseEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.knowledgeBaseDocument],
      },
      knowledgeBaseDestroy: {
        id: 'knowledgeBaseDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
        allowedStorage: [storage.knowledgeBaseDocument],
      },
      knowledgeBaseRead: {
        id: 'knowledgeBaseRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      knowledgeBaseFileFields: {
        id: 'knowledgeBaseFileFields',
        type: 'files',
        fields: {
          document: {
            id: 'document',
            upload: [roles.itAdmin, roles.personalAdmin],
            download: [roles.itAdmin, roles.personalAdmin, roles.personal],
            remove: [roles.itAdmin, roles.personalAdmin],
          },
        },
      },

      mandantNavigate: {
        id: 'mandantNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      mandantCreate: {
        id: 'mandantCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      mandantEdit: {
        id: 'mandantEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      mandantDestroy: {
        id: 'mandantDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      mandantRead: {
        id: 'mandantRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      mandantAutocomplete: {
        id: 'mandantAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      verwendungKostenstelleNavigate: {
        id: 'verwendungKostenstelleNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      verwendungKostenstelleCreate: {
        id: 'verwendungKostenstelleCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      verwendungKostenstelleEdit: {
        id: 'verwendungKostenstelleEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      verwendungKostenstelleDestroy: {
        id: 'verwendungKostenstelleDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      verwendungKostenstelleRead: {
        id: 'verwendungKostenstelleRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      verwendungKostenstelleAutocomplete: {
        id: 'verwendungKostenstelleAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      kostentraegerNavigate: {
        id: 'kostentraegerNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      kostentraegerCreate: {
        id: 'kostentraegerCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      kostentraegerEdit: {
        id: 'kostentraegerEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      kostentraegerDestroy: {
        id: 'kostentraegerDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      kostentraegerRead: {
        id: 'kostentraegerRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      kostentraegerAutocomplete: {
        id: 'kostentraegerAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      kostenstelleNavigate: {
        id: 'kostenstelleNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      kostenstelleCreate: {
        id: 'kostenstelleCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      kostenstelleEdit: {
        id: 'kostenstelleEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      kostenstelleDestroy: {
        id: 'kostenstelleDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      kostenstelleRead: {
        id: 'kostenstelleRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      kostenstelleAutocomplete: {
        id: 'kostenstelleAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      kostenstelleImport: {
        id: 'kostenstelleImport',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },

      hauptkostenstelleNavigate: {
        id: 'hauptkostenstelleNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      hauptkostenstelleCreate: {
        id: 'hauptkostenstelleCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      hauptkostenstelleEdit: {
        id: 'hauptkostenstelleEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      hauptkostenstelleDestroy: {
        id: 'hauptkostenstelleDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      hauptkostenstelleRead: {
        id: 'hauptkostenstelleRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      hauptkostenstelleAutocomplete: {
        id: 'hauptkostenstelleAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      hauptkostenstelleImport: {
        id: 'hauptkostenstelleImport',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },

      berufCreate: {
        id: 'berufCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      berufEdit: {
        id: 'berufEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      berufRead: {
        id: 'berufRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      berufDestroy: {
        id: 'berufDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      berufAutocomplete: {
        id: 'berufAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      templateTypeCreate: {
        id: 'templateTypeCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      templateTypeEdit: {
        id: 'templateTypeEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      templateTypeRead: {
        id: 'templateTypeRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      templateTypeDestroy: {
        id: 'templateTypeDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      templateTypeAutocomplete: {
        id: 'templateTypeAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      calendarCreate: {
        id: 'calendarCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      calendarEdit: {
        id: 'calendarEdit',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      calendarRead: {
        id: 'calendarRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      calendarDestroy: {
        id: 'calendarDestroy',
        allowedRoles: [roles.itAdmin, roles.personalAdmin],
      },
      calendarAutocomplete: {
        id: 'calendarAutocomplete',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      bestaetigungGet: {
        id: 'bestaetigungGet',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      documentGet: {
        id: 'documentGet',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      bestaetigungCreate: {
        id: 'bestaetigungCreate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      statistikAustriaRead: {
        id: 'statistikAustriaRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      statistikAustriaNavigate: {
        id: 'statistikAustriaNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      aenderungNavigate: {
        id: 'aenderungNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      aenderungRead: {
        id: 'aenderungRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      aenderungSendToLohnverrechnung: {
        id: 'aenderungSendToLohnverrechnung',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },

      auswertungRead: {
        id: 'auswertungRead',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
      auswertungNavigate: {
        id: 'auswertungNavigate',
        allowedRoles: [roles.itAdmin, roles.personalAdmin, roles.personal],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }

  static getFilePermissions(model, fieldName) {
    return this.values[model].fields[fieldName];
  }
}

export default Permissions;
